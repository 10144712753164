export const releaseNotes = [
  {
    release_date: "TBD",
    version: "1.4.6",
    notes: {
      1: "Update to get daily sales dashboard for sales reps.",
    },
  },
  {
    release_date: "March 20, 2025",
    version: "1.4.4",
    notes: {
      1: "Update in getting updated Amazon order data."
    },
  },
  {
    release_date: "March 7, 2025",
    version: "1.4.3",
    notes: {
      1: "Amazon transaction list, reconciliation report.",
      2: "Add 'Shipped Orders' filter to Amazon orders list.",
    },
  },
  {
    release_date: "February 13, 2025",
    version: "1.4.2",
    notes: {
      1: "Fix bug in generating amazon orders excel report.",
    },
  },
  {
    release_date: "February 8, 2025",
    version: "1.4.1",
    notes: {
      1: "Update refund list to include Sage Reference Number.",
    },
  },
  {
    release_date: "February 2, 2025",
    version: "1.4.0",
    notes: {
      1: "Optimize Amazon refund transactions.",
      2: "Get Amazon transaction data, breakdowns, and totals.",
      3: "Associate transactions with orders and financial event groups.",
      4: "Search and export refund transactions.",
    },
  },
  {
    release_date: "January 16, 2025",
    version: "1.3.1",
    notes: {
      1: "Include shipping data in Amazon orders.",
      2: "Add reconciliation of Amazon orders with date.",
      3: "Include export to excel for Amazon orders.",
    },
  },
  {
    release_date: "January 11, 2025",
    version: "1.3.0",
    notes: {
      1: "Initial release of Amazon order data, including tax calculations.",
    },
  }
]
